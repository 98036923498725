<template>
  <div id="attendanceRate">...</div>
</template>

<script>
export default {
  data() {
      return {

      }
  }
}
</script>

<style lang="scss">
#attendanceRate {
}
</style>
